import type { init } from '@sentry/nextjs';

import pkg from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const BASE_CONFIG: Parameters<typeof init>[0] = {
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  debug: false,
  release: pkg.version,
};

export default BASE_CONFIG;
